
import { computed, defineComponent, onMounted, onUpdated, ref } from "vue";
import { useI18n } from "vue-i18n";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import ApiService from "@/core/services/ApiService";
import TransportOrderStatusForm from "@/views/catalog/transport_order_status/TransportOrderStatusForm.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "TypeEntities",
  components: {
    TableBasicSelect,
    TransportOrderStatusForm,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const header = ref([
      { column: t("iname"), key: "name" },
      { column: t("iremarks"), key: "remarks" },
    ]);
    const showDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);
    const isLoading = ref(true);
    const search = ref("");
    const tableButtons = ref([]);
    const dataList = ref([]);
    const dataObject = ref([] as any);
    const dialogVisible = ref(false);
    const itemID = ref("");
    const modalMode = ref("add");
    const u = localStorage.getItem("user") as any;
    const user = JSON.parse(u);

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "transport order status: create").length >
            0
        );
      }
      return false;
    });

    const canUpdate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "transport order status: update").length >
            0
        );
      }
      return false;
    });

    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "transport order status: show").length >
            0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "transport order status: delete").length >
            0
        );
      }
      return false;
    });

    const forceDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "transport order status: force_delete").length >
            0
        );
      }
      return false;
    });

    const createElement = () => {
      dialogVisible.value = true;
      itemID.value = "";
      modalMode.value = "add";
    };

    const getItems = () => {
      ApiService.get("/api/transportation-order-status").then(({ data }) => {
        isLoading.value = false;
        const items: Record<string, any> = [];
        dataList.value = data;
        tableButtons.value = [
          {
            name: "Editar" as never,
            type: "EDIT" as never,
            color: "primary" as never,
          } as never,
        ];
        data.forEach((element) => {
          const td = {
            id: element.id,
            name: element.name,
            remarks: element.remarks,
            buttons: [{ type: "EDIT", id: element.id }],
          };
          items.push(td);
        });
        dataObject.value = items;
      });
    };

    const resetModal = () => {
      dialogVisible.value = false;
      getItems();
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      setCurrentPageBreadcrumbs("transport_order_status", [
        { route: "/file", label: "maritime_file" },
      ]);
      getItems();
    });

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      store.commit("setLoading", false);
      ApiService.delete(
        `/api/transportation-order-status/${deleteId.value}?force_delete=true`
      ).then(({ data }) => {
        store.commit("setDeleteId", undefined);
        showDelete.value = false;
        getItems();
      });
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 == "EDIT") {
        dialogVisible.value = true;
        itemID.value = param1;
        modalMode.value = "edit";
      }
      if (param2 == "REMOVE") {
        deleteDialog(param1);
      }
    };

    const onSearch = () => {
      dataObject.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    return {
      forceDelete,
      user,
      canCreate,
      canUpdate,
      canShow,
      canDelete,
      dataList,
      dataObject,
      dialogVisible,
      itemID,
      modalMode,
      header,
      tableButtons,
      isLoading,
      search,
      showDelete,
      deleteDialog,
      deleteElement,
      onSearch,
      actionsButton,
      createElement,
      resetModal,
    };
  },
});
